import type { Brand, dateISO } from '@/shared/model/types/Kernel';
import type { PlacementTypes } from '@/entities/Placement';
import type { CompanyAdvId, CompanyId } from '@/shared/model/types/Company';
import type { Category } from '@/shared/model/types/Category';
import type { MedicineId } from '@/entities/Medicine';

export type CampaignId = Brand<number, 'campaign'>;
export type AdBannerId = Brand<number, 'adBannerId'>;

export enum CampaignStatus {
    ACTIVE = 1,
    INACTIVE = 2,
    BOOKING = 3,
    MODERATION = 4,
    REJECTED = 5,
    DELETED = 6
}

export enum CampaignBindingType {
    CATEGORY = 'CATEGORY',
    GUID = 'GUID',
    CVP = 'CVP',
    KEYWORD = 'KEYWORD',
    PAGE = 'PAGE',
    BRAND = 'BRAND',
    MNN = 'MNN'
}

export enum AdvertisementType {
    SELF_ADVERTISEMENT = 'SELF_ADVERTISEMENT',
    AGENCY = 'AGENCY',
    PROVIDER = 'PROVIDER'
}

export interface Campaign {
    accountingOfBalances: boolean;
    accountNumber: string | null;
    adId: CompanyAdvId;
    adBannerId: AdBannerId | null;
    advertisementType: AdvertisementType;
    applicationNumber: string | null;
    bannersURL: string[];
    bannerCampaignId: CampaignId | null;
    bindingType: boolean;
    binding: CampaignBindingType | null;
    brand: string;
    budget: number;
    budgetOverridden: number | null;
    budgetDaily: number;
    budgetWithoutNds: number;
    contentType: PlacementTypes.PlacementContentType;
    cpm: number;
    dataDispair: boolean;
    description: string;
    discontCPM: number | null;
    endDate: dateISO;
    erId: string | null;
    exclude: MedicineId[];
    goods: Goods[];
    guidState: GuidState[];
    htmlContent: string | null;
    id: CampaignId;
    impsLimit: number | null;
    info: string;
    isAdMark: boolean;
    link: string;
    mediaScoutContractId: string | null;
    moderationComment: string;
    monthCampaign: string;
    monthCommit: string;
    monthPayment: string;
    name: string;
    noNds: boolean;
    note: string;
    paid: string;
    paramState: ParamState[];
    paramTypes: PlacementTypes.PlacementParamType;
    paramValues: string[];
    placementId: PlacementTypes.PlacementId;
    placements: PlacementTypes.PlacementAdId[];
    productCodes: number;
    productName: string;
    productSelect: boolean;
    providerId: CompanyId;
    providerName: string;
    receiveReport: boolean;
    receiveToken: boolean;
    redirectURL: string;
    regions: string[];
    screenshotDate: dateISO | null;
    startDate: dateISO;
    status: CampaignStatus;
    typeId: PlacementTypes.PlacementType;
    text: string | null;
}

export type ParamState = Category;

export interface GuidState {
    CURRENT_BRAND_NAME: string;
    categoryCode: string;
    guid: string;
    is_bestseller: number; // 0 / 1
    kod_cvp: string;
    level1Category: string | null;
    level2Category: string | null;
    level3Category: string | null;
    level4Category: string | null;
    name: string;
    url: string;
}

export interface Goods {
    wabcCode: number;
    wabcName: string;
}

import { omit } from 'lodash';
import { CanceledError } from 'axios';
import client from '@/shared/api/client';
import type { CompanyId } from '@/shared/model/types/Company';
import type { ZdravcityCategoryId } from '@/entities/ZdravcityCategory';
import type { PlacementId } from '@/entities/Placement';
import type { KeywordId } from '@/entities/Keywords';
import type { BookingBrandId } from '@/entities/BookingBrand';
import type { PlacementDocumentId } from '@/entities/PlacementDocuments';
import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { UserId } from '@/shared/model/types/User';
import type { dateISO } from '@/shared/model/types/Kernel';
import { NetworkError } from '@/errors/NetworkError';
import { fileSaveAs } from '@/shared/model/utils';
import { formatCreateFields, formatFilledFields, formatUpdateFields } from './mapper';
import type { Booking, BookingAgreementType, BookingId, BookingOrdStatus, BookingStatus } from '../model/Booking';
import { type BookingChanges, BookingChangeAction } from '../model/BookingChanges';
import { type MultiBooking } from '../model/MultiBooking';
import type { BookingPreset } from '../model/BookingPreset';
import { BookingError } from '../model/BookingError';
import type { BookingKeywordView } from '../model/BookingKeywordView';
import { type PresetFormatParams, presetFormatParams } from './presetFormatParams';

export interface BookingFieldsParams
    extends Pick<
        Booking,
        | 'actNumber'
        | 'allAccountingDocumentsFilled'
        | 'applicationNumber'
        | 'comment'
        | 'documentStatus'
        | 'endDate'
        | 'juridicalName'
        | 'name'
        | 'screenshotSended'
        | 'contentComment'
        | 'startDate'
        | 'cancelComment'
        | 'cancelPrice'
    > {
    ids: BookingId[];
    placementIds: PlacementId[];
    companyIds: CompanyId[];
    brandIds: BookingBrandId[];
    categoryIds: ZdravcityCategoryId[];
    cvpCode: number | null;
    isPrescription: boolean | null;
    status?: BookingStatus;
    adAgreementTypes: BookingAgreementType[];
    erIds: string;
    documentNameIds: PlacementDocumentId[];
    keywordIds: KeywordId[];
    ordStatuses: BookingOrdStatus[];
    createdAtFrom: string;
    createdAtTo: string;
    loaded: boolean | null;
    updatedAtFrom: string;
    updatedAtTo: string;
}

export type BookingFieldsPaginationParams = Partial<BookingFieldsParams & ServerPagination>;

export interface BookingPagination extends Pagination {
    totalImpressions?: number | null;
    totalPriceWithNds?: number | null;
}

export const getBookings = async (params: BookingFieldsPaginationParams, abortController = new AbortController()) => {
    const { currentPage, itemsPerPage, ...otherParams } = params;

    const { data } = await client
        .get<{ data: Booking[]; meta: BookingPagination }>('/api/v1/bookings', {
            params: {
                ...formatFilledFields(otherParams),
                page: currentPage,
                limit: itemsPerPage,
                // TODO: Определять на уровне отдельного метода и компонента
                ...(otherParams.createdAtFrom ? { sort: [{ property: 'createdAt', order: 'asc' }] } : undefined)
            },
            signal: abortController.signal
        })
        .catch(error => {
            if (error.response && !(error instanceof CanceledError)) {
                throw new BookingError(error.response.data.type, error.response);
            }

            return Promise.reject(error);
        });

    return {
        data: data?.data,
        pagination: data?.meta
    };
};

export const createBooking = async (booking: Partial<Omit<Booking, BookingId>>) => {
    try {
        const { data } = await client.post<Booking>('/api/v1/bookings/', formatCreateFields(booking));
        return data;
    } catch (error: unknown) {
        if (BookingError.isBookingError(error)) {
            throw new BookingError(error.response.data.type, error.response);
        }
        throw error;
    }
};

export const getBookingById = async (bookingId: BookingId): Promise<Booking> => {
    const { data } = await client.get<Booking>(`/api/v1/bookings/${bookingId}`).catch(error => {
        if (BookingError.isBookingError(error)) {
            throw new BookingError(error.response.data.type, error.response);
        }
        throw error;
    });

    return data;
};

export const updateBookingById = async (bookingId: BookingId, updateBooking: Partial<Booking>) => {
    try {
        const { data } = await client.patch<Booking>(
            `/api/v1/bookings/${bookingId}`,
            formatUpdateFields(updateBooking)
        );
        return data;
    } catch (error: unknown) {
        if (BookingError.isBookingError(error)) {
            throw new BookingError(error.response.data.type, error.response);
        }
        throw error;
    }
};

export const multiUpdateBookings = async (bookings: Partial<Booking>[]) => {
    try {
        const { data } = await client.patch<Booking>('/api/v1/bookings/multi', {
            data: bookings
        });
        return data;
    } catch (error: unknown) {
        if (BookingError.isBookingError(error)) {
            throw new BookingError(error.response.data.type, error.response);
        }
        throw error;
    }
};

export const deleteBookingById = async (bookingId: BookingId) => {
    await client.delete(`/api/v1/bookings/${bookingId}`);
};

export const createMultiBooking = async (booking: Partial<Omit<Booking, BookingId>>) => {
    try {
        const { data } = await client.post<Booking>('/api/v1/bookings/calendar', omit(booking, 'wabcCode'));
        return data;
    } catch (error: unknown) {
        if (BookingError.isBookingError(error)) {
            throw new BookingError(error.response.data.type, error.response);
        }
        throw error;
    }
};

export interface MultiBookingRangeDates extends Partial<Omit<Booking, BookingId | 'startDate' | 'endDate'>> {
    dates?: { startDate: string; endDate: string }[];
}

export const createMultiBookingMulti = async (booking: MultiBookingRangeDates) => {
    try {
        const { data } = await client.post<Booking>('/api/v1/bookings/calendar/multi', omit(booking, 'wabcCode'));
        return data;
    } catch (error: unknown) {
        if (BookingError.isBookingError(error)) {
            throw new BookingError(error.response.data.type, error.response);
        }
        throw error;
    }
};

export interface MultiBookingParams {
    year: string;
    placementId: PlacementId | null;
    brandIds: BookingBrandId[] | null;
    companyIds: CompanyId[];
    categoryIds: ZdravcityCategoryId[];
    keywordIds: KeywordId[];
    allBindings: boolean;
}

export const getMultiBookings = async (
    params: Partial<MultiBookingParams & ServerPagination>,
    abortController = new AbortController()
) => {
    try {
        const { currentPage, itemsPerPage, ...otherFields } = params;

        const sortPosition = {
            sort: otherFields.allBindings
                ? [
                      { property: 'position', order: 'asc' },
                      { property: 'averageViews', order: 'desc' },
                      { property: 'keywordName', order: 'asc' }
                  ]
                : [{ property: 'createdAt', order: 'desc' }]
        };

        const response = await client.get<{ data: MultiBooking[]; meta: Pagination }>(`/api/v1/bookings/calendar`, {
            params: {
                ...formatFilledFields(otherFields),
                ...sortPosition,
                page: currentPage,
                limit: itemsPerPage
            },
            signal: abortController.signal
        });

        return {
            data: response.data.data,
            pagination: response.data.meta
        };
    } catch (error: unknown) {
        if (error instanceof NetworkError) {
            throw new BookingError(error.response.data.type, error.response);
        }
        throw error;
    }
};

export const deleteMultiBookingParamById = async (
    bookingId: BookingId,
    values?: { categories?: { id: ZdravcityCategoryId }[]; keywords?: { id: KeywordId }[] }
) => {
    try {
        await client.delete(`/api/v1/bookings/calendar/${bookingId}`, { data: values });
    } catch (error: unknown) {
        if (error instanceof NetworkError) {
            throw new BookingError(error.response.data.type, error.response);
        }
        return Promise.reject(error);
    }
};

export const createBookingExcelReport = async (
    params: BookingFieldsParams,
    abortController = new AbortController()
) => {
    const response = await client({
        method: 'GET',
        url: '/api/v1/bookings/report',
        responseType: 'blob',
        params: formatFilledFields(params),
        signal: abortController.signal
    });

    const fileName = `Отчёт по бронированию рекламных кампаний.xlsx`;
    fileSaveAs(response.data, fileName);
};

export interface BookingAvailableKeywordsParams extends ServerPagination {
    year: string;
    keywordIds: KeywordId[];
    placementId: PlacementId;
    excludeBookingId?: BookingId;
}

export const getBookingAvailableKeywords = async (
    params: BookingAvailableKeywordsParams,
    abortController = new AbortController()
) => {
    const { data } = await client.get<{ data: BookingKeywordView[]; meta: Pagination }>(`/api/v1/bookings/keywords`, {
        params: {
            year: params.year,
            ids: params.keywordIds,
            placementId: params.placementId,
            excludeBookingId: params.excludeBookingId,
            page: params.currentPage,
            limit: params.itemsPerPage
        },
        signal: abortController.signal
    });

    return data;
};

export interface BookingChangesParams extends Partial<ServerPagination> {
    userIds?: UserId[];
    companyIds: CompanyId[];
    bookingIds?: BookingId[];
    actions?: BookingChangeAction;
    from?: dateISO;
    until?: dateISO;
}

export const getBookingChanges = async (params: BookingChangesParams, abortController = new AbortController()) => {
    const { currentPage, itemsPerPage, ...otherFields } = params;

    const { data } = await client.get<{ data: BookingChanges[]; meta: Pagination }>(`/api/v1/bookings/journal`, {
        params: {
            ...otherFields,
            page: currentPage,
            limit: itemsPerPage
        },
        signal: abortController.signal
    });

    return {
        data: data.data,
        pagination: data.meta
    };
};

/**
 * Список presets-настроек для бронирования рекламного размещения
 */
export const getBookingPresets = async (params: PresetFormatParams) => {
    const { data } = await client.get<BookingPreset[]>(`/api/v1/bookings/presets`, {
        params: presetFormatParams(params)
    });

    return data;
};

interface UpdateBookingsParams {
    ids: BookingId[];
    booking: Partial<Booking>;
}
interface UpdateBookingResponse {
    id: BookingId;
    name: string;
    updated: Partial<Booking>;
    errors: [
        {
            property: keyof Booking | null;
            messages: string[];
        }
    ];
}
export const updateBookings = async (
    { ids, booking }: UpdateBookingsParams,
    abortController?: AbortController
): Promise<UpdateBookingResponse[]> => {
    const response = await client.post(
        '/api/v1/bookings/update-many',
        {
            query: {
                ids: String(ids)
            },
            data: booking
        },
        {
            signal: abortController?.signal
        }
    );
    return response.data.data;
};

import type { Pagination, ServerPagination } from '@/shared/model/types/Pagination';
import type { ActionPromoId, ReportBonusPackage, ReportBonusPackageByRuble, ReportSaleInPrice } from '../model/types';
import { dayjs } from '@/shared/lib/dayjs';
import { generateUUID, logger } from '@/shared/model/utils';
import client from '@/shared/api/client';
import { NetworkError } from '@/errors/NetworkError';
import type { dateISO } from '@/shared/model/types/Kernel';
import type { ObjectSchema } from 'yup';
import * as yup from 'yup';
import sentry from '@/shared/lib/sentry/sentry';
import type { CompanyProtekId } from '@/shared/model/types/Company';

export interface ActionPromosParams {
    ids?: ActionPromoId[];
    actionNames?: string[];
    startDate?: null | dateISO;
    endDate?: null | dateISO;
    medicineCodes?: number[];
    supplierCode?: CompanyProtekId;
}

const actionPromoReportResponseSchema = yup
    .object()
    .transform(value => {
        return {
            ...value,
            correlationId: value.correlation_id,
            creationDate: value.creation_date,
            totalRecordCount: value.total_record_count
        };
    })
    .shape({
        status: yup.string().matches(/^(\d+)$/),
        message: yup.string().nullable(),
        creationDate: yup.date(),
        correlationId: yup.string().nullable(),
        totalRecordCount: yup.number().integer().required()
    });
const actionPromoReportSaleInPriceSchema: ObjectSchema<ReportSaleInPrice> = yup.object().shape({
    billCdU: yup.number().required(),
    billName: yup.string().required(),
    cdU: yup.number().required(),
    dateOrd: yup.string().required(),
    dateYear: yup.string().matches(/\d{4}/).required(),
    deptName: yup.string().required(),
    docNum: yup.string().required(),
    inn: yup.string().required(),
    medAmn: yup.number().required(),
    medicineCode: yup.number().required(),
    medicineName: yup.string().required(),
    medsPlan: yup.number().required(),
    nmCrossActions: yup.number().required(),
    nmU: yup.string().required(),
    bonusProvider1p: yup.number().required(),
    bonusProvider: yup.number().required(),
    dateAccept: yup.string().required(),
    shipJurAddress: yup.string().required(),
    shipfactAddress: yup.string().required(),
    billJurAddress: yup.string().required(),
    billFactAddress: yup.string().required(),
    divided: yup.string().required(),
    nmActions: yup.string().required(),
    actionName: yup.string().required(),
    remark: yup.string().required(),
    billInn: yup.string().required()
});

const actionPromoReportSaleInPriceResponseSchema = actionPromoReportResponseSchema.shape({
    data: yup.object().shape({
        spActionRep60: yup.object().shape({
            spActionInfoRep60: yup.array().of(actionPromoReportSaleInPriceSchema).required(),
            actionSum: yup.number().required()
        })
    })
});

export const getActionPromoReportSaleInPrice = async (payload: {
    params: ActionPromosParams;
    pagination?: ServerPagination;
}): Promise<{ pagination: Pagination; actionSum: number; data: ReportSaleInPrice[] }> => {
    let startFrom = 0;
    const currentPage = payload.pagination?.currentPage ?? 1;
    const itemsPerPage = payload.pagination?.itemsPerPage ?? 10;
    if (payload.pagination?.currentPage) {
        startFrom = (currentPage - 1) * itemsPerPage;
    }
    const serverPayload = {
        id_action: payload.params?.ids ?? [],
        action_name: payload.params?.actionNames ?? [],
        startdate:
            payload.params.startDate && dayjs(payload.params.startDate).isValid()
                ? dayjs(payload.params.startDate).format('DD.MM.YYYY')
                : undefined,
        enddate:
            payload.params.endDate && dayjs(payload.params.endDate).isValid()
                ? dayjs(payload.params.endDate).format('DD.MM.YYYY')
                : undefined,
        medicine_code: payload.params?.medicineCodes ?? [],
        supplier_code: payload.params?.supplierCode ? [payload.params.supplierCode] : [],
        start_from: String(startFrom),
        count: String(itemsPerPage),
        cache: '0',
        correlation_id: generateUUID()
    };
    const response = await client.post(`/api/proxy/report/promo-action-info-rep60`, serverPayload);

    await actionPromoReportSaleInPriceResponseSchema
        .validate(response.data, {
            abortEarly: false,
            stripUnknown: true
        })
        .catch(error => {
            const message = 'Не валидный ответ /api/proxy/report/rep-actions';
            sentry.captureMessage(message);
            sentry.captureException(error);
            logger.warn(message);
            logger.error(error);
        });

    const data = actionPromoReportSaleInPriceResponseSchema.cast(response.data, {
        stripUnknown: true,
        assert: false
    });

    if (response.data.status !== '0') {
        throw new NetworkError('Ответ от протек пришел с неизвестным статусом', response);
    }

    const totalItems = Number(response.data.total_record_count);
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pagination: Pagination = {
        totalItems,
        currentPageItemsCount: data.data.spActionRep60.spActionInfoRep60.length,
        itemsPerPage,
        totalPages,
        currentPage
    };
    return {
        actionSum: data.data.spActionRep60.actionSum,
        data: data.data.spActionRep60.spActionInfoRep60,
        pagination
    };
};

const actionPromoReportBonusPackageSchema: ObjectSchema<ReportBonusPackage> = yup.object().shape({
    actionName: yup.string().required(),
    billCdU: yup.number().required(),
    billName: yup.string().required(),
    bonusDocNum: yup.string().required(),
    bonusName: yup.string().required(),
    bonusRealized: yup.number().required(),
    cdU: yup.number().required(),
    compensation: yup.number().required(),
    compensationCdU: yup.number().nullable().defined(),
    contractPrice: yup.number().nullable().defined(),
    dateOrd: yup.string().required(),
    dateYear: yup.string().matches(/\d{4}/).required(),
    deptName: yup.string().required(),
    docNum: yup.string().required(),
    inn: yup.string().required(),
    medAmn: yup.number().required(),
    medsBonus: yup.number().required(),
    nmActions: yup.number().required(),
    medicineCode: yup.number().required(),
    medicineName: yup.string().required(),
    medsPlan: yup.number().required(),
    nmCrossActions: yup.number().required(),
    nmU: yup.string().required(),
    filCdu: yup.number().required(),
    shipJurAddress: yup.string().required(),
    shipfactAddress: yup.string().required(),
    groupCond: yup.string().required(),
    nmAgent: yup.string().required(),
    billJurAddress: yup.string().required(),
    billFactAddress: yup.string().required(),
    bonus: yup.string().required(),
    docDate: yup.string().required(),
    fpriceWoNds: yup.number().required(),
    fpriceWNds: yup.number().required(),
    divided: yup.number().required(),
    bonusMetrics: yup.string().required(),
    bonusNachisl: yup.number().required(),
    bonusDocDate: yup.string().required(),
    dolg: yup.number().required(),
    segmentId: yup.number().required(),
    segmentName: yup.string().required(),
    asName: yup.string().required(),
    saleManagment: yup.string().required(),
    souzfarmaFlag: yup.string().required()
});

const actionPromoReportBonusPackageResponseSchema = actionPromoReportResponseSchema.shape({
    data: yup.object().shape({
        spActionRep35: yup.object().shape({
            spActionInfoRep35: yup.array().of(actionPromoReportBonusPackageSchema).required(),
            actionSum: yup.number().required()
        })
    })
});

export const getActionPromoReportBonusPackage = async (payload: {
    params: ActionPromosParams;
    pagination?: ServerPagination;
}): Promise<{ actionSum: number; data: ReportBonusPackage[]; pagination: Pagination }> => {
    let startFrom = 0;
    const currentPage = payload.pagination?.currentPage ?? 1;
    const itemsPerPage = payload.pagination?.itemsPerPage ?? 10;
    if (payload.pagination?.currentPage) {
        startFrom = (currentPage - 1) * itemsPerPage;
    }
    const serverPayload = {
        id_action: payload.params?.ids ?? [],
        action_name: payload.params?.actionNames ?? [],
        startdate0:
            payload.params.startDate && dayjs(payload.params.startDate).isValid()
                ? dayjs(payload.params.startDate).format('DD.MM.YYYY')
                : undefined,
        enddate0:
            payload.params.endDate && dayjs(payload.params.endDate).isValid()
                ? dayjs(payload.params.endDate).format('DD.MM.YYYY')
                : undefined,
        medicine_code: payload.params?.medicineCodes ?? [],
        supplier_code: payload.params?.supplierCode ? [payload.params.supplierCode] : [],
        start_from: String(startFrom),
        count: String(itemsPerPage),
        cache: '0',
        correlation_id: generateUUID()
    };
    const url = `/api/proxy/report/promo-action-info-rep35`;
    const response = await client.post(url, serverPayload);

    if (response.data.status !== '0') {
        throw new NetworkError('Ответ от протек пришел с неизвестным статусом', response);
    }

    await actionPromoReportBonusPackageResponseSchema
        .validate(response.data, {
            abortEarly: false,
            stripUnknown: true
        })
        .catch(error => {
            const message = `Не валидный ответ ${url}`;
            sentry.captureMessage(message);
            sentry.captureException(error);
            logger.warn(message);
            logger.error(error);
        });

    const data = actionPromoReportBonusPackageResponseSchema.cast(response.data, {
        stripUnknown: true,
        assert: false
    });

    const totalItems = Number(response.data.total_record_count);
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pagination: Pagination = {
        totalItems,
        currentPageItemsCount: data.data.spActionRep35.spActionInfoRep35.length,
        itemsPerPage,
        totalPages,
        currentPage
    };
    return {
        actionSum: data.data.spActionRep35.actionSum,
        data: data.data.spActionRep35.spActionInfoRep35,
        pagination
    };
};

const actionPromoReportBonusPackageByRubleSchema: ObjectSchema<ReportBonusPackageByRuble> = yup
    .object()
    .transform(value => {
        return {
            ...value,
            planBegin: value.plan_begin,
            planEnd: value.plan_end
        };
    })
    .shape({
        actionName: yup.string().required(),
        nmActions: yup.number().required(),
        deptName: yup.string().required(),
        cdU: yup.number().required(),
        nmU: yup.string().required(),
        billCdU: yup.number().required(),
        billName: yup.string().required(),
        inn: yup.string().required(),
        planBegin: yup.string().required(),
        planEnd: yup.string().required(),
        factSum: yup.string().required(),
        docsNumPay: yup.string().required(),
        dateOrdPay: yup.string().required(),
        bonusName: yup.string().required(),
        medsBonus: yup.number().required(),
        bonusRealized: yup.number().required(),
        docsNumFree: yup.string().required(),
        contractPrice: yup.number().defined(),
        compensation: yup.number().required(),
        compensationCdU: yup.number().defined(),
        medsPlan: yup.string().required(),
        shipJurAddress: yup.string().required(),
        shipfactAddress: yup.string().required(),
        billJurAddress: yup.string().required(),
        billFactAddress: yup.string().required(),
        billInn: yup.string().required(),
        docDate: yup.string().required(),
        bonusNachisl: yup.string().required(),
        bonusDocDate: yup.string().required()
    });

const actionPromoReportBonusPackageByRubleResponseSchema = actionPromoReportResponseSchema.shape({
    data: yup.object().shape({
        spActionRep89: yup.object().shape({
            spActionInfoRep89: yup.array().of(actionPromoReportBonusPackageByRubleSchema).required(),
            actionSum: yup.number().required()
        })
    })
});

export const getActionPromoReportBonusPackageByRuble = async (payload: {
    params: ActionPromosParams;
    pagination?: ServerPagination;
}): Promise<{ pagination: Pagination; actionSum: number; data: ReportBonusPackageByRuble[] }> => {
    let startFrom = 0;
    const currentPage = payload.pagination?.currentPage ?? 1;
    const itemsPerPage = payload.pagination?.itemsPerPage ?? 10;
    if (payload.pagination?.currentPage) {
        startFrom = (currentPage - 1) * itemsPerPage;
    }
    const serverPayload = {
        id_action: payload.params?.ids ?? [],
        action_name: payload.params?.actionNames ?? [],
        startdate:
            payload.params.startDate && dayjs(payload.params.startDate).isValid()
                ? dayjs(payload.params.startDate).format('DD.MM.YYYY')
                : undefined,
        enddate:
            payload.params.endDate && dayjs(payload.params.endDate).isValid()
                ? dayjs(payload.params.endDate).format('DD.MM.YYYY')
                : undefined,
        medicine_code: payload.params?.medicineCodes ?? [],
        supplier_code: payload.params?.supplierCode ? [payload.params.supplierCode] : [],
        start_from: String(startFrom),
        count: String(itemsPerPage),
        cache: '0',
        correlation_id: generateUUID()
    };
    const response = await client.post(`/api/proxy/report/promo-action-info-rep89`, serverPayload);
    const data = actionPromoReportBonusPackageByRubleResponseSchema.cast(response.data, {
        stripUnknown: true,
        assert: false
    });

    if (response.data.status !== '0') {
        throw new NetworkError('Ответ от протек пришел с неизвестным статусом', response);
    }

    const totalItems = Number(response.data.total_record_count);
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pagination: Pagination = {
        totalItems,
        currentPageItemsCount: data.data.spActionRep89.spActionInfoRep89.length,
        itemsPerPage,
        totalPages,
        currentPage
    };
    return {
        actionSum: data.data.spActionRep89.actionSum,
        data: data.data.spActionRep89.spActionInfoRep89,
        pagination
    };
};

import type { Brand } from '@/shared/model/types/Kernel';
import type { PlacementPermission } from '@/shared/model/types/Permission';
import type { BookingPresetId } from '@/entities/Booking';
import type { PlacementDocument } from '@/entities/PlacementDocuments';

export type PlacementId = Brand<number, 'placement'>;
export type PlacementAdId = Brand<number, 'placementAd'>;

export enum PlacementParamType {
    /**
     * Без привязки
     */
    DEFAULT = 0,

    /**
     * Категория
     */
    CATEGORY = 1,

    /**
     * Место в карусели
     */
    CAROUSEL = 2,

    /**
     * Привязка к словам
     */
    WORDS = 3,

    /**
     *  Привязка к кодам товара (GUID)
     */
    GUID = 4,

    /**
     *  Привязка к действующему веществу (МНН)
     */
    MNN = 5,

    /**
     *  Страница спец. мероприятий
     */
    SPECIAL_EVENTS = 6,

    /**
     * Привязка к бренду
     */
    BRAND = 7,

    /**
     * Привязка к категории или CVP коду товара
     */
    CATEGORY_OR_CVP = 8,

    /**
     * Привязка к баннеру
     */
    BANNER = 9
}

export enum PlacementContentType {
    BANNER = 1,
    HTML = 2
}

export enum PlacementType {
    BY_TIME = 3,
    CPM = 1,
    EXTERNAL = 4
}

export interface Placement {
    adId: PlacementAdId | null;
    adSalesReport: boolean;
    adTag: string;
    baseOrganicProfileUrl: string | null;
    bookingPresetId: BookingPresetId | null;
    carouselMixed: boolean;
    carouselValue: number;
    contentType: PlacementContentType;
    height: number;
    htmlCode: string | null;
    htmlTemplate: string | null;
    id: PlacementId;
    isActive: boolean;
    isAdMark: boolean;
    isNewSite: boolean;
    isRedirect: boolean;
    mediumHeight: string | null;
    mediumWidth: string | null;
    name: string;
    paramType: PlacementParamType;
    permissionKey: PlacementPermission | null;
    previewBannerURL: string | null;
    price: number;
    receiveToken: boolean;
    sizes: { width: number; height: number }[];
    smallHeight: string | null;
    smallWidth: string | null;
    stubPlacementUrl: string | null;
    textId: string;
    type: PlacementType;
    width: number;
    withText: boolean;
    namesInDocuments: PlacementDocument[];
    usePriceRanges: boolean;
    showInMultibooking: boolean;
    showInBooking: boolean;
    allowPrescription: boolean;
}
